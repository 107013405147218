.rdrMonthAndYearWrapper {
  align-items: center;
  height: 30px;
  padding-top: 0px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
  outline: 0;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:focus
  ::before
  :hover {
  border-color: #666;
}
