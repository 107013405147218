@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Login {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.e-grid{
  z-index: 0;
}
.e-grid td.e-rowcell {
  /* cursor: pointer;
  text-decoration: underline; */
}
/* .e-grid td.e-rowcell:hover {
    cursor: pointer;
    text-decoration: underline;
    font:blue;
    } */
.FormFields {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 1px solid #ccc;
  padding: 40px;
  width: 400px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.LogoName {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.LogoName p {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1em;
  margin: 0;
}

.Fields {
  padding: 20px 10px;
  border: 1px solid #ccc;
}

.SubmitBtn {
  padding: 10px 10px;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: bold;
  background-color: #337ab7;
  color: #fff;
}

.ErrorMsgEmail {
  color: red;
  font-size: 0.9em;
  position: absolute;
  margin-top: -20px;
}
.ErrorMsgPass {
  color: red;
  font-size: 0.9em;
  position: absolute;
  margin-top: 68px;
}

/* sidebar logo design */

.sidebar-logo {
  padding: 10px 0px 0px 5px;
  cursor: pointer;
}

.Header {
  background-color: #235dae;
  display: flex;
  flex-direction: row;
  font-size: 1.5em;
  font-weight: bold;
  position: absolute;
  top: 0;
  height: 70px;
  width: 100vw;
  z-index: 102;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.Header p {
  color: #fff;
  margin: 18px;
  border-left: 2px solid #fff;
  padding-left: 15px;
}

.pro-sidebar {
  z-index: 101 !important;
  
}
@media screen 
and (min-device-width: 1200px) 
and (max-device-width: 1600px)  {
  .pro-sidebar {
    z-index: 101 !important;
    height: 150vh !important;
  }
}

.logo-text {
  display: flex;
  flex-direction: row;
  padding-top: 13px;
  padding-left: 20px;
}

.ListContainer {
  position: absolute;
  z-index: 999;
}

.logo-text img {
  height: 40px;
}

.logo-text p {
  font-size: 0.7em;
  text-align: left;
  margin: 0;
  padding-left: 10px;
  border: none;
  margin-top: -6px;
}

.Dashboard {
  height: 100vh;
  width: 100%;
  z-index: 9;
}
.ContainerDashboard {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  flex-grow: 1;
  align-items: stretch;
  overflow: auto;
  background: #f2f2f2;
  
}
.Homepage {
  width: 100%;
  z-index: 9;
  margin-top: 4.5rem;
  margin-left: 0.7rem;
  scroll-behavior: auto;
 
}

.Container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  flex-grow: 1;
  align-items: stretch;
  overflow: hidden;
}

.DataGridContainer {
  display: flex;
  flex-direction: column;
  margin: 80px 20px 20px 20px;
  flex-grow: 20;
  overflow-y: hidden;
  height: calc(100vh - 150px);
}

.e-gridheader {
  position: -webkit-sticky;
  position: sticky;
  top: 40px; /* The height of top nav menu. */
  z-index: 1;
}
.e-toolbar {
  position: sticky !important;
  top: 0; /* The height of top nav menu. */
  z-index: 1;
}

.e-pager {
  position: sticky !important;
  bottom: 0;
  z-index: 1;
  border-bottom: 1px solid #e5e7eb !important;
}

.Grid {
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 250px);
}

.DataGridContainer h1 {
  font-size: 0.95em;
  font-weight: bold;
  text-align: left;
  background-color: #235dae2a;
  width: fit-content;
  padding: 10px 20px 12px 20px;
}

.Dropdowns {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  column-gap: 1em;
}

.datePicker {
  display: flex;
  flex-direction: row;
  column-gap: 1em;
}

.labelDrop {
  display: flex;
  flex-direction: column;
}

.labelDrop p {
  margin: 0;
  color: #323743;
  font-weight: 500;
  padding-bottom: 5px;
  font-size: 0.95em;
}

.react-date-picker__wrapper {
  padding: 5.5px 5px;
  width: 200px;
}

.rmsc {
  --rmsc-main: #006edc !important;
  --rmsc-border: #808080 !important;
  --rmsc-radius: none !important;
}

.dropdown-container {
  width: 300px;
  padding: 0px 0;
}
.dropdown-container:focus-within {
  box-shadow: none !important;
}

.btn {
  width: 150px;
  height: 40px;
  margin-top: 25px;
  color: #fff;
  font-weight: bold;
  font-size: 0.9em;
  background-color: #006edc;
  border: 1px solid #006edc;
  cursor: pointer;
}

.btn:hover,
.btn2:hover {
  background-color: #0064c9;
  border: 1px solid #0064c9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.btn2 {
  width: 150px;
  height: 40px;
  color: #fff;
  font-weight: bold;
  font-size: 0.9em;
  background-color: #006edc;
  border: 1px solid #006edc;
  margin-bottom: 10px;
  cursor: pointer;
}

.List {
  position: absolute;
  top: 115px;
  margin-left: 10px;
  background-color: #333333;
  width: 310px;
  height: calc(100vh - 150px);
  z-index: 9;
  overflow-y: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.ListHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  z-index: 99;
  width: 295.5px;
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #235dae;
  color: #fff;
  font-weight: bold;
  font-size: 0.9em;
  margin-top: 80px;
  cursor: pointer;
  width: 310px;
}

.leaflet-bar {
  border-radius: 0px !important;
}

.FilterIcon {
  position: absolute;
  right: 10px;
  bottom: 131px;
  z-index: 9999;
  cursor: pointer;
  height: 34px;
  width: 34px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 20%);
  border: 2px solid rgba(0, 0, 0, 0.2);
  color: rgb(102, 102, 102);
}

.Link {
  text-decoration: none;
  font-size: 0.9em;
  color: rgb(38, 118, 238);
  cursor: pointer;
  margin-bottom: 5px;
}

.KeyInfo {
  background-color: #333;
  color: #fff;
  font-weight: bold;
  width: 320px;
  height: 170px;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.KeyInfoBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.KeyInfo p {
  margin: 2px 0;
}

#datetimepicker_timepopup {
  background-color: #fff !important;
}

.Player {
  background-color: #333;
  padding: 10px;
  height: 60px;
  width: 320px;
  display: flex;
  flex-direction: row;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.e-input-group.e-control-wrapper {
  border-color: #000 !important;
  border: 2px solid !important;
  height: 44px;
  font-size: 15px;
  width: 100%;
  padding-top: 5px;
}
